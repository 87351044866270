import React from "react"

const WebRapida = () => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="426.65"
    height="355.7"
    viewBox="0 0 426.65 355.7"
  >
    <g>
      <g>
        <g>
          <path
            d="M285.94,143.38l16.85,200.37s1.77,12.14-7.27,12.17c-14.85,0-73.93,0-73.93,0s11.18-2.53,11-8.22c-.16-6.71-19.69-206.21-19.69-206.21Z"
            transform="translate(-42.28 -0.29)"
            fill="#112a93"
          />
          <path
            d="M216.79,352.28c2.93,0,59.35.19,73.82,0,6.3-.08,7.41-5.6,7.46-9.18a17.41,17.41,0,0,0-.19-3l-.44-5.21-1.11-13.28-1-11.72-1.27-15.07L293,282.12,281,139.75l-73-1.9s8.22,83.49,14,144.27c.65,6.77,1.27,13.26,1.84,19.32q.28,2.91.54,5.67c.54,5.75,1,11,1.46,15.7.23,2.45.44,4.74.63,6.83.74,8.2,1.17,13.43,1.18,14.42a9.34,9.34,0,0,1-.14,1.63C226.38,352.07,217.89,352.28,216.79,352.28Z"
            transform="translate(-42.28 -0.29)"
            fill="#3a54c1"
          />
          <polygon
            points="420.96 34.06 379.91 198.94 374.29 221.52 355.17 298.31 0 298.31 70.46 34.06 420.96 34.06"
            fill="#1d38c7"
          />
          <polygon
            points="420.96 34.06 426.65 35.96 362.74 304.05 355.17 298.31 420.96 34.06"
            fill="#1d38c7"
          />
          <polygon
            points="362.74 304.05 9.75 304.05 0 298.31 355.17 298.31 362.74 304.05"
            fill="#112a93"
          />
          <rect
            x="162.35"
            y="352.07"
            width="76.43"
            height="3.63"
            fill="#3a54c1"
          />
          <polygon
            points="181.78 304.05 182.17 306.82 254.05 321.34 253.06 309.62 252.59 304.05 181.78 304.05"
            fill="#112a93"
          />
          <path
            d="M216.79,352.28c2.93,0,59.35.19,73.82,0,6.3-.08,7.41-5.6,7.46-9.18l-71.53-13.46c.74,8.2,1.17,13.43,1.18,14.42a9.34,9.34,0,0,1-.14,1.63C226.38,352.07,217.89,352.28,216.79,352.28Z"
            transform="translate(-42.28 -0.29)"
            fill="#1d38c7"
          />
          <polygon
            points="199.15 244.82 355.17 298.31 374.29 221.52 199.15 244.82"
            fill="#112a93"
          />
          <polygon
            points="420.96 34.06 70.46 34.06 13.6 246.43 369.89 246.43 420.96 34.06"
            fill="#469df0"
          />
        </g>
        <path
          d="M210.45,273.57a12,12,0,0,1,11.26-9.43c5.21,0,8.61,4.22,7.6,9.43A12,12,0,0,1,218.05,283C212.84,283,209.44,278.78,210.45,273.57Z"
          transform="translate(-42.28 -0.29)"
          fill="#112a93"
        />
        <polygon
          points="30.13 237.23 358.29 237.23 395.46 89.18 398.31 77.83 400.74 68.14 406.44 45.44 83 45.44 76.74 68.14 74.07 77.83 70.94 89.18 30.13 237.23"
          fill="#e5f2fd"
        />
        <polygon
          points="76.74 68.14 400.74 68.14 406.44 45.44 83 45.44 76.74 68.14"
          fill="#a2cdfc"
        />
        <g>
          <path
            d="M433.2,53.15A2.15,2.15,0,0,1,435.34,56a3.89,3.89,0,0,1-3.55,2.84A2.14,2.14,0,0,1,429.66,56,3.88,3.88,0,0,1,433.2,53.15Z"
            transform="translate(-42.28 -0.29)"
            fill="#e5f2fd"
          />
          <path
            d="M423.77,53.15A2.14,2.14,0,0,1,425.9,56a3.87,3.87,0,0,1-3.54,2.84A2.14,2.14,0,0,1,420.23,56,3.88,3.88,0,0,1,423.77,53.15Z"
            transform="translate(-42.28 -0.29)"
            fill="#e5f2fd"
          />
          <path
            d="M410.07,56a3.88,3.88,0,0,1,3.55-2.84A2.15,2.15,0,0,1,415.75,56a3.88,3.88,0,0,1-3.55,2.84A2.14,2.14,0,0,1,410.07,56Z"
            transform="translate(-42.28 -0.29)"
            fill="#e5f2fd"
          />
        </g>
        <path
          d="M131.76,93.37l2.44-9.78a4.47,4.47,0,0,1,4.07-3.26H420.55A2.47,2.47,0,0,1,423,83.59l-2.43,9.78a4.48,4.48,0,0,1-4.08,3.26H134.21A2.47,2.47,0,0,1,131.76,93.37Z"
          transform="translate(-42.28 -0.29)"
          fill="#a2cdfc"
        />
        <path
          d="M222.11,80.33l-4.06,16.3H416.48a4.48,4.48,0,0,0,4.08-3.26L423,83.59a2.47,2.47,0,0,0-2.44-3.26Z"
          transform="translate(-42.28 -0.29)"
          fill="#f46969"
        />
        <path
          d="M105.44,191.71a5,5,0,0,1,4.61-3.68H273.27a2.78,2.78,0,0,1,2.76,3.68,5,5,0,0,1-4.6,3.68H108.21A2.79,2.79,0,0,1,105.44,191.71Z"
          transform="translate(-42.28 -0.29)"
          fill="#a2cdfc"
        />
        <g>
          <g>
            <path
              d="M297.6,210.64c-.88,0-1.42-.62-1.2-1.39h0a2.15,2.15,0,0,1,2-1.38h70.79c.88,0,1.42.62,1.2,1.38h0a2.14,2.14,0,0,1-2,1.39Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M296.28,215.25c-.88,0-1.42-.62-1.2-1.38h0a2.14,2.14,0,0,1,2-1.39h70.79c.88,0,1.42.62,1.2,1.39h0a2.15,2.15,0,0,1-2,1.38Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M295.7,217.29h70.79c.88,0,1.42.62,1.2,1.39a2.15,2.15,0,0,1-2,1.39H294.9c-.88,0-1.42-.62-1.2-1.39A2.14,2.14,0,0,1,295.7,217.29Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M206.24,210.64c-.88,0-1.42-.62-1.2-1.39h0a2.15,2.15,0,0,1,2-1.38h70.79c.88,0,1.42.62,1.2,1.38h0a2.14,2.14,0,0,1-2,1.39Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M204.92,215.25c-.88,0-1.42-.62-1.2-1.38h0a2.14,2.14,0,0,1,2-1.39h70.79c.88,0,1.42.62,1.2,1.39h0a2.15,2.15,0,0,1-2,1.38Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M204.34,217.29h70.79c.88,0,1.42.62,1.2,1.39a2.15,2.15,0,0,1-2,1.39H203.54c-.88,0-1.42-.62-1.2-1.39A2.14,2.14,0,0,1,204.34,217.29Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M117.69,210.64c-.88,0-1.42-.62-1.2-1.39h0a2.15,2.15,0,0,1,2-1.38h70.79c.88,0,1.42.62,1.2,1.38h0a2.13,2.13,0,0,1-2,1.39Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M116.37,215.25c-.88,0-1.42-.62-1.2-1.38h0a2.14,2.14,0,0,1,2-1.39H188c.88,0,1.42.62,1.2,1.39h0a2.15,2.15,0,0,1-2,1.38Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
            <path
              d="M113.79,218.68a2.14,2.14,0,0,1,2-1.39h70.79c.88,0,1.42.62,1.2,1.39a2.15,2.15,0,0,1-2,1.39H115C114.11,220.07,113.57,219.45,113.79,218.68Z"
              transform="translate(-42.28 -0.29)"
              fill="#f46969"
            />
          </g>
          <path
            d="M97.12,226.77a3,3,0,0,1-.61-2.93l5.45-19a5.85,5.85,0,0,1,5.46-3.78h273a3.91,3.91,0,0,1,3,1.19,3,3,0,0,1,.62,2.93l-5.45,19A5.87,5.87,0,0,1,373.1,228h-273A3.91,3.91,0,0,1,97.12,226.77Zm10.3-24.34a4.26,4.26,0,0,0-3.91,2.73l-5.45,19a1.81,1.81,0,0,0,.32,1.75,2.27,2.27,0,0,0,1.72.65h273a4.25,4.25,0,0,0,3.9-2.73l5.45-19a1.77,1.77,0,0,0-.32-1.74,2.26,2.26,0,0,0-1.71-.65Z"
            transform="translate(-42.28 -0.29)"
            fill="#f46969"
          />
        </g>
        <path
          d="M397.31,178.27a6.34,6.34,0,0,1-5.78,4.63l-279.78-.79a3.5,3.5,0,0,1-3.48-4.63l19.39-71.37a6.33,6.33,0,0,1,5.78-4.63H411.66a3.5,3.5,0,0,1,3.47,4.63Z"
          transform="translate(-42.28 -0.29)"
          fill="#a2cdfc"
        />
      </g>
      <g>
        <polygon
          points="244.96 55.39 18.51 55.32 34.89 0 261.34 0.07 244.96 55.39"
          fill="#e5f2fd"
        />
        <g>
          <path
            d="M283.59,19.06l-2,6.62a7.79,7.79,0,0,1-7,5.29L87,30.91a3.87,3.87,0,0,1-3.87-5.29l2-6.62a7.79,7.79,0,0,1,7-5.29l187.61.06C282.71,13.77,284.45,16.15,283.59,19.06Z"
            transform="translate(-42.28 -0.29)"
            fill="#80b4ff"
          />
          <g>
            <path
              d="M245.18,20.43H242.3l.25-.86h4l-.22.74-4,4.83h-1.12Z"
              transform="translate(-42.28 -0.29)"
              fill="#2c2c2c"
            />
            <path
              d="M246.46,22.36a3.81,3.81,0,0,1,3.36-2.88c1.49,0,2.1,1.27,1.63,2.88a3.81,3.81,0,0,1-3.36,2.88C246.59,25.24,246,24,246.46,22.36Zm3.95,0c.32-1.1,0-2-.87-2s-1.71.88-2,2,0,2,.87,2S250.08,23.48,250.41,22.36Z"
              transform="translate(-42.28 -0.29)"
              fill="#2c2c2c"
            />
            <path
              d="M252.73,20.94a2,2,0,0,1,1.78-1.43c.8,0,1.15.65.92,1.43a2,2,0,0,1-1.78,1.42C252.85,22.36,252.5,21.72,252.73,20.94Zm5.17-1.36h.81l-5.84,5.57h-.81Zm-3.2,1.36c.13-.45,0-.81-.39-.81a1,1,0,0,0-.85.81c-.13.45,0,.81.39.81A1,1,0,0,0,254.7,20.94Zm.65,2.85a2,2,0,0,1,1.78-1.43c.8,0,1.15.65.92,1.43a2,2,0,0,1-1.78,1.42C255.46,25.21,255.12,24.57,255.35,23.79Zm2,0c.13-.45,0-.81-.39-.81a1,1,0,0,0-.86.81c-.13.45,0,.81.39.81A1,1,0,0,0,257.32,23.79Z"
              transform="translate(-42.28 -0.29)"
              fill="#2c2c2c"
            />
          </g>
          <g>
            <path
              d="M219,13.75,213.88,31,87,30.91A3.78,3.78,0,0,1,82.93,27a4.69,4.69,0,0,1,.22-1.4l2-6.62a7.79,7.79,0,0,1,7-5.29Z"
              transform="translate(-42.28 -0.29)"
              fill="#ff4d67"
            />
            <path
              d="M114.57,13.72,98.73,30.92H87A3.78,3.78,0,0,1,82.93,27L95.19,13.71Z"
              transform="translate(-42.28 -0.29)"
              fill="#ff2948"
            />
            <polygon
              points="105.49 13.44 89.65 30.63 70.28 30.63 86.11 13.43 105.49 13.44"
              fill="#ff2948"
            />
            <polygon
              points="138.69 13.45 122.85 30.64 103.47 30.64 119.31 13.44 138.69 13.45"
              fill="#ff2948"
            />
            <polygon
              points="171.89 13.46 156.06 30.66 136.68 30.65 152.52 13.45 171.89 13.46"
              fill="#ff2948"
            />
          </g>
        </g>
        <g>
          <polygon
            points="72.93 40.82 39.05 40.81 39.73 38.53 73.61 38.54 72.93 40.82"
            fill="#2c2c2c"
          />
          <polygon
            points="61.79 47.18 37.17 47.17 37.84 44.9 62.46 44.9 61.79 47.18"
            fill="#2c2c2c"
          />
          <polygon
            points="78.02 47.18 63.3 47.18 63.98 44.9 78.69 44.91 78.02 47.18"
            fill="#2c2c2c"
          />
          <polygon
            points="103.05 47.19 80.57 47.18 81.24 44.91 103.72 44.92 103.05 47.19"
            fill="#2c2c2c"
          />
          <polygon
            points="85.74 40.82 75.48 40.82 76.16 38.55 86.41 38.55 85.74 40.82"
            fill="#2c2c2c"
          />
          <polygon
            points="126.56 40.84 89.25 40.82 89.92 38.55 127.23 38.56 126.56 40.84"
            fill="#2c2c2c"
          />
        </g>
      </g>
      <g>
        <polygon
          points="395.29 250.99 231.59 250.55 252.9 173.8 416.61 174.24 395.29 250.99"
          fill="#e5f2fd"
        />
        <polygon
          points="395.57 212.59 252.63 212.2 261.69 179.58 404.63 179.97 395.57 212.59"
          fill="#80b4ff"
        />
        <g>
          <polygon
            points="291.96 186.3 267.75 186.23 268.24 184.49 292.45 184.56 291.96 186.3"
            fill="#cae0ff"
          />
          <polygon
            points="317.86 186.37 297.73 186.31 298.21 184.57 318.35 184.63 317.86 186.37"
            fill="#cae0ff"
          />
          <polygon
            points="364.73 186.49 323.11 186.38 323.59 184.64 365.22 184.75 364.73 186.49"
            fill="#cae0ff"
          />
          <polygon
            points="290.23 192.53 266.02 192.47 266.5 190.73 290.72 190.79 290.23 192.53"
            fill="#cae0ff"
          />
          <polygon
            points="316.13 192.6 295.99 192.55 296.48 190.81 316.62 190.86 316.13 192.6"
            fill="#cae0ff"
          />
          <polygon
            points="339.92 192.67 319.78 192.61 320.26 190.87 340.4 190.93 339.92 192.67"
            fill="#cae0ff"
          />
          <polygon
            points="387.2 193.52 345.57 193.41 346.06 191.67 387.68 191.78 387.2 193.52"
            fill="#cae0ff"
          />
        </g>
        <g>
          <polygon
            points="288.04 200.44 263.82 200.37 264.31 198.63 288.52 198.7 288.04 200.44"
            fill="#cae0ff"
          />
          <polygon
            points="313.94 200.51 293.8 200.45 294.28 198.71 314.42 198.77 313.94 200.51"
            fill="#cae0ff"
          />
          <polygon
            points="360.81 200.63 319.18 200.52 319.67 198.78 361.29 198.89 360.81 200.63"
            fill="#cae0ff"
          />
          <polygon
            points="286.31 206.67 262.09 206.61 262.58 204.87 286.79 204.93 286.31 206.67"
            fill="#cae0ff"
          />
          <polygon
            points="312.2 206.74 292.07 206.69 292.55 204.95 312.69 205 312.2 206.74"
            fill="#cae0ff"
          />
          <polygon
            points="335.99 206.81 315.85 206.75 316.33 205.01 336.47 205.07 335.99 206.81"
            fill="#cae0ff"
          />
          <polygon
            points="383.27 207.66 341.65 207.55 342.13 205.81 383.75 205.92 383.27 207.66"
            fill="#cae0ff"
          />
        </g>
        <g>
          <g>
            <path
              d="M374.29,239.75c-5.55,0-8.91-4.19-7.49-9.3s7.09-9.25,12.64-9.24,8.9,4.19,7.48,9.3S379.84,239.76,374.29,239.75ZM379,222.91c-4.54,0-9.17,3.37-10.33,7.55s1.58,7.58,6.12,7.6,9.17-3.38,10.33-7.56S383.5,222.92,379,222.91Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
            <path
              d="M375.35,235.19a1.15,1.15,0,0,1-.87-.43l-3.17-4.18a1.46,1.46,0,0,1,.6-2,1.59,1.59,0,0,1,2.15,0l2.24,3,4.64-4.34a1.76,1.76,0,0,1,2.22-.31,1.3,1.3,0,0,1-.2,2l-6.09,5.71a2.17,2.17,0,0,1-1.3.58Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M402.92,239.75c-5.55,0-8.91-4.19-7.49-9.3s7.09-9.25,12.63-9.24,8.91,4.19,7.49,9.3S408.46,239.76,402.92,239.75Zm4.67-16.84c-4.53,0-9.16,3.37-10.32,7.55s1.58,7.58,6.12,7.6,9.17-3.38,10.33-7.56S412.13,222.92,407.59,222.91Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
            <path
              d="M404,235.19a1.15,1.15,0,0,1-.87-.43l-3.17-4.18a1.46,1.46,0,0,1,.6-2,1.59,1.59,0,0,1,2.15,0l2.24,3,4.64-4.34a1.76,1.76,0,0,1,2.22-.31,1.3,1.3,0,0,1-.2,2l-6.1,5.71a2.12,2.12,0,0,1-1.29.58Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M345.5,239.67c-5.55,0-8.91-4.18-7.49-9.29s7.09-9.26,12.64-9.24,8.9,4.18,7.48,9.29S351.05,239.69,345.5,239.67Zm4.67-16.84c-4.53,0-9.16,3.37-10.32,7.55S341.43,238,346,238s9.17-3.38,10.33-7.55S354.71,222.84,350.17,222.83Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
            <path
              d="M346.56,235.12a1.16,1.16,0,0,1-.87-.44l-3.17-4.17a1.47,1.47,0,0,1,.6-2,1.6,1.6,0,0,1,2.15.05l2.24,2.95,4.64-4.34a1.76,1.76,0,0,1,2.22-.31,1.3,1.3,0,0,1-.2,2l-6.1,5.71a2.12,2.12,0,0,1-1.29.58Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M315.67,239.59c-5.54,0-8.9-4.18-7.48-9.29s7.08-9.26,12.63-9.24,8.91,4.18,7.49,9.29S321.22,239.61,315.67,239.59Zm4.68-16.84c-4.53,0-9.17,3.37-10.33,7.55s1.59,7.59,6.12,7.6,9.17-3.38,10.33-7.55S324.89,222.76,320.35,222.75Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
            <path
              d="M316.74,235a1.2,1.2,0,0,1-.88-.44l-3.17-4.17a1.48,1.48,0,0,1,.61-2,1.6,1.6,0,0,1,2.15,0l2.24,3,4.63-4.34a1.78,1.78,0,0,1,2.23-.31,1.3,1.3,0,0,1-.21,2l-6.09,5.71a2.12,2.12,0,0,1-1.3.58Z"
              transform="translate(-42.28 -0.29)"
              fill="#5dd782"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default WebRapida
