import React from "react"

const WebProfesional = () => (
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    width="481"
    height="354.88"
    viewBox="0 0 481 354.88"
  >
    <g>
      <g>
        <g>
          <path
            d="M216.9,145.7,200.26,343.51s-1.74,12,7.18,12c14.67,0,73,0,73,0s-11-2.5-10.9-8.11c.16-6.62,19.44-203.58,19.44-203.58Z"
            transform="translate(-0.39 -0.72)"
            fill="#112a93"
          />
          <path
            d="M285.16,351.93c-2.88,0-58.59.18-72.87,0-6.22-.08-7.32-5.53-7.37-9.06a18.41,18.41,0,0,1,.19-2.95l.43-5.14,1.11-13.1,1-11.58,1.25-14.88,1.06-12.55,11.82-140.56,72.07-1.87S285.7,222.66,280,282.67c-.64,6.68-1.25,13.09-1.81,19.07-.19,1.91-.36,3.78-.53,5.6-.54,5.68-1,10.89-1.45,15.49-.22,2.43-.43,4.68-.61,6.75-.74,8.09-1.17,13.26-1.17,14.24a8.08,8.08,0,0,0,.14,1.6C275.7,351.73,284.08,351.93,285.16,351.93Z"
            transform="translate(-0.39 -0.72)"
            fill="#3a54c1"
          />
          <polygon
            points="41.48 37.34 82 200.11 87.55 222.41 106.42 298.22 457.06 298.22 387.5 37.34 41.48 37.34"
            fill="#1d38c7"
          />
          <polygon
            points="41.47 37.34 35.86 39.21 98.95 303.88 106.42 298.22 41.47 37.34"
            fill="#1d38c7"
          />
          <polygon
            points="98.95 303.88 447.44 303.88 457.06 298.22 106.42 298.22 98.95 303.88"
            fill="#112a93"
          />
          <rect
            x="221.72"
            y="352.01"
            width="75.45"
            height="3.59"
            transform="translate(518.51 706.89) rotate(180)"
            fill="#3a54c1"
          />
          <polygon
            points="277.6 303.88 277.21 306.62 206.25 320.96 207.23 309.38 207.69 303.88 277.6 303.88"
            fill="#112a93"
          />
          <path
            d="M285.16,351.93c-2.88,0-58.59.18-72.87,0-6.22-.08-7.32-5.53-7.37-9.06l70.63-13.29c-.74,8.09-1.17,13.26-1.17,14.24a8.08,8.08,0,0,0,.14,1.6C275.7,351.73,284.08,351.93,285.16,351.93Z"
            transform="translate(-0.39 -0.72)"
            fill="#1d38c7"
          />
          <polygon
            points="260.45 245.41 106.42 298.22 87.55 222.41 260.45 245.41"
            fill="#112a93"
          />
          <polygon
            points="41.47 37.34 387.5 37.34 443.64 247.01 93.67 247.01 41.47 37.34"
            fill="#3a3a5b"
          />
        </g>
        <path
          d="M291.43,274.23a11.86,11.86,0,0,0-11.12-9.31c-5.14,0-8.5,4.17-7.5,9.31a11.86,11.86,0,0,0,11.12,9.31C289.07,283.54,292.43,279.37,291.43,274.23Z"
          transform="translate(-0.39 -0.72)"
          fill="#112a93"
        />
        <polygon
          points="427.32 237.92 103.34 237.92 66.65 91.76 63.84 80.55 61.44 70.99 55.81 48.57 375.12 48.57 381.3 70.99 383.94 80.55 387.03 91.76 427.32 237.92"
          fill="#80b4ff"
        />
        <polygon
          points="381.3 70.99 61.44 70.99 55.81 48.57 375.12 48.57 381.3 70.99"
          fill="#7398e6"
        />
        <g>
          <path
            d="M69.41,59.42a2.11,2.11,0,0,1,2.1-2.8A3.84,3.84,0,0,1,75,59.42a2.12,2.12,0,0,1-2.11,2.8A3.82,3.82,0,0,1,69.41,59.42Z"
            transform="translate(-0.39 -0.72)"
            fill="#ecf1f9"
          />
          <path
            d="M78.72,59.42a2.12,2.12,0,0,1,2.11-2.8,3.83,3.83,0,0,1,3.5,2.8,2.12,2.12,0,0,1-2.11,2.8A3.83,3.83,0,0,1,78.72,59.42Z"
            transform="translate(-0.39 -0.72)"
            fill="#ecf1f9"
          />
          <path
            d="M88.75,59.42a2.11,2.11,0,0,1,2.1-2.8,3.81,3.81,0,0,1,3.5,2.8,2.11,2.11,0,0,1-2.1,2.8A3.81,3.81,0,0,1,88.75,59.42Z"
            transform="translate(-0.39 -0.72)"
            fill="#ecf1f9"
          />
        </g>
        <polygon
          points="355.95 224.96 115.97 224.96 79.45 81.64 316.04 81.64 355.95 224.96"
          fill="#3a3a5b"
        />
        <polygon
          points="386.99 114.98 332.59 114.98 322.65 81.64 377.7 81.64 386.99 114.98"
          fill="#ecf1f9"
        />
        <polygon
          points="240.25 95.59 209.13 95.59 207.84 90.79 238.97 90.79 240.25 95.59"
          fill="#8a89a9"
        />
        <polygon
          points="295.42 95.59 244.51 95.59 243.23 90.79 294.14 90.79 295.42 95.59"
          fill="#80b4ff"
        />
        <polygon
          points="245.48 108.52 190.28 108.52 189 103.72 244.2 103.72 245.48 108.52"
          fill="#80b4ff"
        />
        <polygon
          points="279.05 108.52 250.44 108.52 249.16 103.72 277.77 103.72 279.05 108.52"
          fill="#fcba64"
        />
        <polygon
          points="249.64 130.78 218.52 130.78 217.24 125.98 248.36 125.98 249.64 130.78"
          fill="#80b4ff"
        />
        <polygon
          points="304.81 130.78 253.9 130.78 252.62 125.98 303.53 125.98 304.81 130.78"
          fill="#80b4ff"
        />
        <polygon
          points="254.87 143.71 199.68 143.71 198.4 138.91 253.59 138.91 254.87 143.71"
          fill="#ecf1f9"
        />
        <polygon
          points="288.44 143.71 259.83 143.71 258.55 138.91 287.16 138.91 288.44 143.71"
          fill="#ecf1f9"
        />
        <polygon
          points="221.45 163.72 116.16 163.72 114.88 158.92 220.17 158.92 221.45 163.72"
          fill="#8a89a9"
        />
        <polygon
          points="259.3 163.72 228.18 163.72 226.9 158.92 258.02 158.92 259.3 163.72"
          fill="#80b4ff"
        />
        <polygon
          points="314.48 163.72 263.57 163.72 262.29 158.92 313.2 158.92 314.48 163.72"
          fill="#80b4ff"
        />
        <polygon
          points="150.73 176.64 119.61 176.64 118.33 171.85 149.45 171.85 150.73 176.64"
          fill="#ecf1f9"
        />
        <polygon
          points="264.53 176.64 209.34 176.64 208.06 171.85 263.25 171.85 264.53 176.64"
          fill="#fcba64"
        />
        <polygon
          points="205.91 176.64 155 176.64 153.72 171.85 204.63 171.85 205.91 176.64"
          fill="#80b4ff"
        />
        <polygon
          points="298.1 176.64 269.5 176.64 268.22 171.85 296.82 171.85 298.1 176.64"
          fill="#80b4ff"
        />
        <polygon
          points="233.4 199.41 128.11 199.41 126.83 194.61 232.12 194.61 233.4 199.41"
          fill="#8a89a9"
        />
        <polygon
          points="271.25 199.41 240.13 199.41 238.85 194.61 269.97 194.61 271.25 199.41"
          fill="#80b4ff"
        />
        <polygon
          points="162.68 212.33 131.56 212.33 130.28 207.54 161.4 207.54 162.68 212.33"
          fill="#ecf1f9"
        />
        <polygon
          points="276.48 212.33 221.29 212.33 220.01 207.54 275.2 207.54 276.48 212.33"
          fill="#fcba64"
        />
        <polygon
          points="217.86 212.33 166.95 212.33 165.67 207.54 216.58 207.54 217.86 212.33"
          fill="#80b4ff"
        />
        <polygon
          points="310.06 212.33 281.45 212.33 280.17 207.54 308.78 207.54 310.06 212.33"
          fill="#80b4ff"
        />
        <g>
          <polygon
            points="359.91 125.3 335.97 125.37 335.44 123.2 359.38 123.13 359.91 125.3"
            fill="#ecf1f9"
          />
          <polygon
            points="372.53 125.26 363.53 125.29 363 123.11 372 123.09 372.53 125.26"
            fill="#ecf1f9"
          />
          <polygon
            points="390.77 125.2 374.87 125.25 374.34 123.08 390.25 123.03 390.77 125.2"
            fill="#ecf1f9"
          />
          <polygon
            points="350.43 130.83 337.31 130.87 336.78 128.7 349.9 128.66 350.43 130.83"
            fill="#ecf1f9"
          />
          <polygon
            points="369.57 130.77 352.62 130.82 352.09 128.65 369.04 128.6 369.57 130.77"
            fill="#ecf1f9"
          />
          <polygon
            points="392.12 130.7 372.69 130.76 372.16 128.59 391.58 128.53 392.12 130.7"
            fill="#ecf1f9"
          />
        </g>
        <g>
          <polygon
            points="362.77 137.04 338.83 137.12 338.3 134.94 362.24 134.87 362.77 137.04"
            fill="#ecf1f9"
          />
          <polygon
            points="375.39 137 366.39 137.03 365.86 134.86 374.86 134.83 375.39 137"
            fill="#ecf1f9"
          />
          <polygon
            points="393.64 136.95 377.73 137 377.2 134.82 393.11 134.78 393.64 136.95"
            fill="#ecf1f9"
          />
          <polygon
            points="353.3 142.57 340.17 142.61 339.64 140.44 352.77 140.4 353.3 142.57"
            fill="#ecf1f9"
          />
          <polygon
            points="372.43 142.51 355.48 142.57 354.95 140.39 371.9 140.34 372.43 142.51"
            fill="#ecf1f9"
          />
          <polygon
            points="394.98 142.44 375.56 142.5 375.03 140.33 394.45 140.27 394.98 142.44"
            fill="#ecf1f9"
          />
        </g>
        <g>
          <polygon
            points="344 86.26 329.58 86.31 329.27 85 343.68 84.95 344 86.26"
            fill="#3a3a5b"
          />
          <polygon
            points="351.6 86.24 346.18 86.26 345.86 84.95 351.28 84.93 351.6 86.24"
            fill="#3a3a5b"
          />
          <polygon
            points="362.59 86.21 353.01 86.24 352.69 84.93 362.27 84.9 362.59 86.21"
            fill="#3a3a5b"
          />
          <polygon
            points="338.29 89.59 330.39 89.62 330.07 88.31 337.98 88.28 338.29 89.59"
            fill="#3a3a5b"
          />
          <polygon
            points="349.82 89.56 339.61 89.59 339.29 88.28 349.5 88.25 349.82 89.56"
            fill="#3a3a5b"
          />
          <polygon
            points="363.39 89.52 351.7 89.55 351.38 88.24 363.07 88.21 363.39 89.52"
            fill="#3a3a5b"
          />
        </g>
        <g>
          <polygon
            points="345.72 93.34 331.31 93.38 330.99 92.07 345.4 92.03 345.72 93.34"
            fill="#3a3a5b"
          />
          <polygon
            points="353.32 93.31 347.9 93.33 347.58 92.02 353.01 92 353.32 93.31"
            fill="#3a3a5b"
          />
          <polygon
            points="364.31 93.28 354.73 93.31 354.41 92 363.99 91.97 364.31 93.28"
            fill="#3a3a5b"
          />
          <polygon
            points="340.02 96.67 332.12 96.69 331.8 95.38 339.7 95.36 340.02 96.67"
            fill="#3a3a5b"
          />
          <polygon
            points="351.54 96.63 341.33 96.66 341.02 95.35 351.22 95.32 351.54 96.63"
            fill="#3a3a5b"
          />
          <polygon
            points="365.12 96.59 353.42 96.63 353.1 95.32 364.8 95.28 365.12 96.59"
            fill="#3a3a5b"
          />
        </g>
        <g>
          <polygon
            points="367.66 157.11 343.73 157.18 343.19 155.01 367.13 154.94 367.66 157.11"
            fill="#ecf1f9"
          />
          <polygon
            points="380.29 157.07 371.29 157.1 370.75 154.93 379.75 154.9 380.29 157.07"
            fill="#ecf1f9"
          />
          <polygon
            points="398.53 157.01 382.62 157.06 382.09 154.89 398 154.84 398.53 157.01"
            fill="#ecf1f9"
          />
          <polygon
            points="358.19 162.64 345.06 162.68 344.54 160.51 357.66 160.47 358.19 162.64"
            fill="#ecf1f9"
          />
          <polygon
            points="377.32 162.58 360.38 162.63 359.85 160.46 376.79 160.41 377.32 162.58"
            fill="#ecf1f9"
          />
          <polygon
            points="399.87 162.51 380.45 162.57 379.92 160.4 399.34 160.34 399.87 162.51"
            fill="#ecf1f9"
          />
        </g>
        <g>
          <polygon
            points="372.45 176.78 348.52 176.85 347.99 174.68 371.93 174.6 372.45 176.78"
            fill="#ecf1f9"
          />
          <polygon
            points="385.08 176.74 376.08 176.77 375.55 174.59 384.55 174.56 385.08 176.74"
            fill="#ecf1f9"
          />
          <polygon
            points="403.32 176.68 387.42 176.73 386.88 174.56 402.79 174.51 403.32 176.68"
            fill="#ecf1f9"
          />
          <polygon
            points="362.98 182.31 349.86 182.35 349.33 180.17 362.45 180.13 362.98 182.31"
            fill="#ecf1f9"
          />
          <polygon
            points="382.12 182.25 365.17 182.3 364.64 180.13 381.59 180.07 382.12 182.25"
            fill="#ecf1f9"
          />
          <polygon
            points="404.66 182.18 385.24 182.24 384.71 180.06 404.13 180 404.66 182.18"
            fill="#ecf1f9"
          />
        </g>
        <g>
          <polygon
            points="376.77 194.5 352.84 194.57 352.31 192.4 376.24 192.32 376.77 194.5"
            fill="#ecf1f9"
          />
          <polygon
            points="389.4 194.46 380.4 194.49 379.87 192.31 388.87 192.28 389.4 194.46"
            fill="#ecf1f9"
          />
          <polygon
            points="407.64 194.4 391.73 194.45 391.2 192.28 407.11 192.23 407.64 194.4"
            fill="#ecf1f9"
          />
          <polygon
            points="367.3 200.03 354.18 200.07 353.65 197.89 366.77 197.85 367.3 200.03"
            fill="#ecf1f9"
          />
          <polygon
            points="386.44 199.97 369.49 200.02 368.96 197.85 385.9 197.79 386.44 199.97"
            fill="#ecf1f9"
          />
          <polygon
            points="408.98 199.9 389.56 199.96 389.03 197.78 408.45 197.72 408.98 199.9"
            fill="#ecf1f9"
          />
        </g>
        <g>
          <g>
            <path
              d="M388.44,221.57a10.08,10.08,0,0,1-9-7c-1.12-3.84,1.09-7,4.94-7a10.07,10.07,0,0,1,9,7C394.51,218.44,392.29,221.57,388.44,221.57Zm-3.7-12.68c-3.14,0-4.95,2.56-4,5.7a8.21,8.21,0,0,0,7.36,5.7c3.15,0,5-2.55,4-5.7A8.24,8.24,0,0,0,384.74,208.89Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M387.3,218.14a1.47,1.47,0,0,1-.78-.32l-3.91-3.14a1.15,1.15,0,0,1-.41-1.5,1.08,1.08,0,0,1,1.51,0l2.77,2.22,1.42-3.28c.2-.47.83-.58,1.41-.24a1.31,1.31,0,0,1,.67,1.49l-1.86,4.3a.76.76,0,0,1-.67.44Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M407.7,221.57a10.08,10.08,0,0,1-9-7c-1.12-3.84,1.09-7,4.94-7a10.07,10.07,0,0,1,9,7C413.77,218.44,411.55,221.57,407.7,221.57ZM404,208.89c-3.14,0-4.95,2.56-4,5.7a8.24,8.24,0,0,0,7.37,5.7c3.14,0,5-2.55,4-5.7A8.24,8.24,0,0,0,404,208.89Z"
              transform="translate(-0.39 -0.72)"
              fill="#ff2948"
            />
            <g>
              <path
                d="M406,215.73h0a1.44,1.44,0,0,1-1.28-1l-.86-3a.71.71,0,0,1,.7-1h0a1.42,1.42,0,0,1,1.27,1l.87,3A.71.71,0,0,1,406,215.73Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
              <path
                d="M406.78,218.06h-.21a1.3,1.3,0,0,1-1.14-.88h0a.64.64,0,0,1,.63-.88h.21a1.29,1.29,0,0,1,1.14.88h0A.64.64,0,0,1,406.78,218.06Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path
              d="M367,112.49a5.54,5.54,0,0,1-4.94-3.83,2.77,2.77,0,0,1,2.71-3.82,5.51,5.51,0,0,1,4.94,3.82A2.77,2.77,0,0,1,367,112.49Zm-2-6.95a2.26,2.26,0,0,0-2.22,3.12,4.52,4.52,0,0,0,4,3.13,2.26,2.26,0,0,0,2.21-3.13A4.5,4.5,0,0,0,365,105.54Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M366.36,110.61a.81.81,0,0,1-.43-.18l-2.15-1.72a.62.62,0,0,1-.22-.82.58.58,0,0,1,.83,0l1.52,1.21.77-1.8c.12-.26.46-.31.78-.13a.73.73,0,0,1,.37.82l-1,2.36a.4.4,0,0,1-.36.24Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M356,112.49a5.53,5.53,0,0,1-4.94-3.83,2.77,2.77,0,0,1,2.71-3.82,5.52,5.52,0,0,1,4.94,3.82A2.78,2.78,0,0,1,356,112.49Zm-2-6.95a2.26,2.26,0,0,0-2.21,3.12,4.5,4.5,0,0,0,4,3.13,2.27,2.27,0,0,0,2.21-3.13A4.51,4.51,0,0,0,354,105.54Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M355.41,110.61a.82.82,0,0,1-.42-.18l-2.15-1.72a.62.62,0,0,1-.22-.82.58.58,0,0,1,.83,0l1.51,1.21.78-1.8c.11-.26.46-.31.77-.13a.72.72,0,0,1,.37.82l-1,2.36a.4.4,0,0,1-.36.24Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M344.7,112.49a5.54,5.54,0,0,1-4.94-3.83,2.77,2.77,0,0,1,2.71-3.82,5.51,5.51,0,0,1,4.94,3.82A2.77,2.77,0,0,1,344.7,112.49Zm-2-6.95a2.26,2.26,0,0,0-2.22,3.12,4.51,4.51,0,0,0,4,3.13,2.26,2.26,0,0,0,2.21-3.13A4.5,4.5,0,0,0,342.68,105.54Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M344.08,110.61a.81.81,0,0,1-.43-.18l-2.15-1.72a.62.62,0,0,1-.22-.82.58.58,0,0,1,.83,0l1.52,1.21.77-1.8c.12-.26.46-.31.78-.13a.73.73,0,0,1,.37.82l-1,2.36a.4.4,0,0,1-.36.24Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M377.54,112.49a5.53,5.53,0,0,1-4.94-3.83,2.77,2.77,0,0,1,2.71-3.82,5.51,5.51,0,0,1,4.94,3.82A2.77,2.77,0,0,1,377.54,112.49Zm-2-6.95a2.26,2.26,0,0,0-2.21,3.12,4.51,4.51,0,0,0,4,3.13,2.26,2.26,0,0,0,2.21-3.13A4.51,4.51,0,0,0,375.51,105.54Z"
              transform="translate(-0.39 -0.72)"
              fill="#ff2948"
            />
            <g>
              <path
                d="M376.61,109.29h0a.8.8,0,0,1-.7-.54l-.47-1.63a.39.39,0,0,1,.38-.54h0a.78.78,0,0,1,.7.54l.47,1.63A.39.39,0,0,1,376.61,109.29Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
              <path
                d="M377,110.56h-.12a.69.69,0,0,1-.62-.48h0a.35.35,0,0,1,.34-.48h.12a.71.71,0,0,1,.62.48h0A.34.34,0,0,1,377,110.56Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
            </g>
          </g>
        </g>
      </g>
      <g>
        <polygon
          points="481 55.82 258.58 55.82 241.54 0 463.95 0 481 55.82"
          fill="#ecf1f9"
        />
        <g>
          <path
            d="M455.88,19.59l2,6.68c.9,2.93-.77,5.34-3.71,5.34H269.94a7.88,7.88,0,0,1-7-5.34l-2-6.68c-.9-2.94.77-5.34,3.71-5.34H448.91A7.85,7.85,0,0,1,455.88,19.59Z"
            transform="translate(-0.39 -0.72)"
            fill="#80b4ff"
          />
          <g>
            <path
              d="M419,21h-2.82l-.27-.87h3.95l.23.74L419,25.74h-1.11Z"
              transform="translate(-0.39 -0.72)"
              fill="#2c2c2c"
            />
            <path
              d="M421.39,22.93c-.49-1.61.1-2.91,1.57-2.91a3.84,3.84,0,0,1,3.33,2.91c.49,1.61-.09,2.91-1.57,2.91A3.85,3.85,0,0,1,421.39,22.93Zm3.88,0c-.33-1.11-1.18-2-2-2s-1.15.89-.81,2,1.18,2,2.06,2S425.62,24.05,425.27,22.93Z"
              transform="translate(-0.39 -0.72)"
              fill="#2c2c2c"
            />
            <path
              d="M426.7,21.49c-.24-.78.1-1.44.9-1.44a2,2,0,0,1,1.75,1.44c.24.78-.1,1.44-.89,1.44A2,2,0,0,1,426.7,21.49ZM431,20.12h.8l-2.4,5.62h-.79Zm-2.32,1.37a1.08,1.08,0,0,0-.87-.82c-.38,0-.49.37-.35.82a1.05,1.05,0,0,0,.86.82C428.66,22.31,428.77,21.94,428.64,21.49ZM431,24.37c-.24-.78.1-1.44.89-1.44a2,2,0,0,1,1.76,1.44c.24.78-.1,1.43-.9,1.43A2,2,0,0,1,431,24.37Zm1.93,0a1.07,1.07,0,0,0-.87-.82c-.37,0-.49.37-.35.82a1.08,1.08,0,0,0,.87.82C432.94,25.19,433.05,24.82,432.91,24.37Z"
              transform="translate(-0.39 -0.72)"
              fill="#2c2c2c"
            />
          </g>
          <g>
            <path
              d="M389.24,14.25l5.3,17.36H269.94a7.84,7.84,0,0,1-6.34-3.92,6.62,6.62,0,0,1-.63-1.42l-2-6.68c-.9-2.94.77-5.34,3.71-5.34Z"
              transform="translate(-0.39 -0.72)"
              fill="#ff4d67"
            />
            <path
              d="M286.7,14.25l-5.26,17.36h-11.5a7.84,7.84,0,0,1-6.34-3.92l4.06-13.44Z"
              transform="translate(-0.39 -0.72)"
              fill="#ff2948"
            />
            <polygon
              points="318.91 13.53 313.66 30.89 294.63 30.89 299.88 13.53 318.91 13.53"
              fill="#ff2948"
            />
            <polygon
              points="351.52 13.53 346.27 30.89 327.24 30.89 332.49 13.53 351.52 13.53"
              fill="#ff2948"
            />
            <polygon
              points="384.13 13.53 378.88 30.89 359.85 30.89 365.1 13.53 384.13 13.53"
              fill="#ff2948"
            />
          </g>
        </g>
        <g>
          <polygon
            points="303.35 41.17 270.07 41.17 269.37 38.88 302.64 38.88 303.35 41.17"
            fill="#2c2c2c"
          />
          <polygon
            points="296.21 47.59 272.03 47.59 271.33 45.3 295.51 45.3 296.21 47.59"
            fill="#2c2c2c"
          />
          <polygon
            points="312.15 47.59 297.7 47.59 297 45.3 311.45 45.3 312.15 47.59"
            fill="#2c2c2c"
          />
          <polygon
            points="336.73 47.59 314.65 47.59 313.95 45.3 336.03 45.3 336.73 47.59"
            fill="#2c2c2c"
          />
          <polygon
            points="315.92 41.17 305.85 41.17 305.15 38.88 315.22 38.88 315.92 41.17"
            fill="#2c2c2c"
          />
          <polygon
            points="356.01 41.17 319.37 41.17 318.67 38.88 355.31 38.88 356.01 41.17"
            fill="#2c2c2c"
          />
        </g>
      </g>
      <g>
        <polygon
          points="180.79 140.07 24.32 140.07 0 60.42 156.47 60.42 180.79 140.07"
          fill="#ecf1f9"
        />
        <polygon
          points="158.7 100.25 22.08 100.25 11.75 66.4 148.37 66.4 158.7 100.25"
          fill="#80b4ff"
        />
        <g>
          <polygon
            points="44.55 73.28 21.41 73.28 20.85 71.47 44 71.47 44.55 73.28"
            fill="#cae0ff"
          />
          <polygon
            points="69.3 73.28 50.05 73.28 49.5 71.47 68.75 71.47 69.3 73.28"
            fill="#cae0ff"
          />
          <polygon
            points="114.1 73.28 74.32 73.28 73.77 71.47 113.55 71.47 114.1 73.28"
            fill="#cae0ff"
          />
          <polygon
            points="46.52 79.75 23.38 79.75 22.83 77.94 45.97 77.94 46.52 79.75"
            fill="#cae0ff"
          />
          <polygon
            points="71.28 79.75 52.03 79.75 51.48 77.94 70.72 77.94 71.28 79.75"
            fill="#cae0ff"
          />
          <polygon
            points="94.01 79.75 74.76 79.75 74.21 77.94 93.46 77.94 94.01 79.75"
            fill="#cae0ff"
          />
          <polygon
            points="139.62 80.5 99.84 80.5 99.29 78.69 139.07 78.69 139.62 80.5"
            fill="#cae0ff"
          />
        </g>
        <g>
          <polygon
            points="49.03 87.95 25.89 87.95 25.33 86.14 48.48 86.14 49.03 87.95"
            fill="#cae0ff"
          />
          <polygon
            points="73.78 87.95 54.53 87.95 53.98 86.14 73.23 86.14 73.78 87.95"
            fill="#cae0ff"
          />
          <polygon
            points="118.58 87.95 78.8 87.95 78.25 86.14 118.03 86.14 118.58 87.95"
            fill="#cae0ff"
          />
          <polygon
            points="51 94.42 27.86 94.42 27.31 92.61 50.45 92.61 51 94.42"
            fill="#cae0ff"
          />
          <polygon
            points="75.76 94.42 56.51 94.42 55.96 92.61 75.21 92.61 75.76 94.42"
            fill="#cae0ff"
          />
          <polygon
            points="98.49 94.42 79.24 94.42 78.69 92.61 97.94 92.61 98.49 94.42"
            fill="#cae0ff"
          />
          <polygon
            points="144.1 95.17 104.32 95.17 103.77 93.37 143.55 93.37 144.1 95.17"
            fill="#cae0ff"
          />
        </g>
        <g>
          <g>
            <path
              d="M114.09,129c-5.31,0-10.94-4.31-12.56-9.61s1.38-9.62,6.68-9.62,10.94,4.31,12.56,9.62S119.39,129,114.09,129Zm-5.34-17.48c-4.34,0-6.79,3.53-5.46,7.87s5.92,7.86,10.26,7.86,6.78-3.53,5.46-7.86S113.08,111.52,108.75,111.52Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M112.45,124.28a2.11,2.11,0,0,1-1.09-.45l-5.45-4.32a1.6,1.6,0,0,1-.59-2.07,1.48,1.48,0,0,1,2.09,0l3.85,3.06,1.89-4.52c.28-.65,1.15-.8,1.95-.33a1.81,1.81,0,0,1,1,2l-2.49,5.94a1,1,0,0,1-.9.6Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M86.57,129c-5.3,0-10.94-4.31-12.56-9.61s1.38-9.62,6.68-9.62,10.94,4.31,12.56,9.62S91.87,129,86.57,129Zm-5.34-17.48c-4.33,0-6.78,3.53-5.46,7.87s5.93,7.86,10.26,7.86,6.79-3.53,5.46-7.86S85.57,111.52,81.23,111.52Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M84.93,124.28a2,2,0,0,1-1.08-.45l-5.46-4.32a1.58,1.58,0,0,1-.58-2.07,1.47,1.47,0,0,1,2.08,0l3.85,3.06,1.9-4.52c.27-.65,1.14-.8,1.94-.33a1.8,1.8,0,0,1,1,2L86,123.68a1,1,0,0,1-.9.6Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M58.06,129c-5.3,0-10.93-4.31-12.55-9.61s1.38-9.62,6.68-9.62,10.94,4.31,12.55,9.62S63.37,129,58.06,129Zm-5.33-17.48c-4.34,0-6.79,3.53-5.46,7.87s5.92,7.86,10.26,7.86,6.78-3.53,5.46-7.86S57.06,111.52,52.73,111.52Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
            <path
              d="M56.43,124.28a2.07,2.07,0,0,1-1.09-.45l-5.45-4.32a1.6,1.6,0,0,1-.59-2.07,1.48,1.48,0,0,1,2.09,0l3.85,3.06L57.13,116c.28-.65,1.15-.8,1.95-.33a1.81,1.81,0,0,1,1,2l-2.49,5.94a1,1,0,0,1-.9.6Z"
              transform="translate(-0.39 -0.72)"
              fill="#5dd782"
            />
          </g>
          <g>
            <path
              d="M140.63,129c-5.3,0-10.93-4.31-12.55-9.61s1.38-9.62,6.68-9.62,10.94,4.31,12.55,9.62S145.94,129,140.63,129Zm-5.33-17.48c-4.34,0-6.79,3.53-5.46,7.87s5.92,7.86,10.26,7.86,6.78-3.53,5.46-7.86S139.63,111.52,135.3,111.52Z"
              transform="translate(-0.39 -0.72)"
              fill="#ff2948"
            />
            <g>
              <path
                d="M138.18,121h0a2,2,0,0,1-1.78-1.36l-1.24-4.09a1,1,0,0,1,.94-1.36h0a2,2,0,0,1,1.78,1.36l1.24,4.09A1,1,0,0,1,138.18,121Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
              <path
                d="M139.3,124.17H139a1.78,1.78,0,0,1-1.58-1.22h0a.86.86,0,0,1,.84-1.21h.29a1.81,1.81,0,0,1,1.59,1.21h0A.88.88,0,0,1,139.3,124.17Z"
                transform="translate(-0.39 -0.72)"
                fill="#ff2948"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default WebProfesional
